

.game_view_action {
  &:disabled {
    opacity: .7;
  }
  height: 48px;
  width: 48px;
  font-size: 30px;
  padding: 5px;
  border-radius: 25%;
  border: 0;
  color: #333;
  background-color: #fff;

  transition: 300ms border-color ease;

  &.is-animated {
    background-color: #777;
  }

  &.is-toggled {
    border: 3px solid gold;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;


  .anime {
    border-radius: 25%;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-color: #fff;
  }

  &__count {
    color: #666;
    text-shadow: 1px 1px 2px #b9b2b2;
    position: absolute;
    z-index: 99999;
    top: 0;
    pointer-events: none;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
}
