.entity_view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;


    &.is-active {
        .entity_view__icon {
            box-shadow: 0 0 3px rgba(0,0,0,.1);
            border-radius: 35%;
            background-color: rgba(0,0,0,.1);
        }
    }

    &__icon {
        font-size: 2em;
        padding: .5em;
        margin: .5em;
    }

    &__stats {
        position: absolute;
        bottom: -2em;
        white-space: nowrap;
        text-shadow: 1px 1px 2px #b9b2b2;
    }
}
