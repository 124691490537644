.entity_inventory {
}

.entity_inventory_item {
  background-color: rgba(0,0,0,.7);
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: all;
  color: #fff;
  border: 1px solid #aaa;
  cursor: pointer;

  &__section{
    margin: 0 1em;
  }

  & > * {
    margin-right: .3em;
  }

  & > * + * {
    margin-right: 0;
  }

  &__icon {
    border: 1px solid #aaa;
    background-color: rgba(0,0,0,.7);
    padding: 1em;
    position: relative;
  }
  &__count {
    position: absolute;
    color: #fff;
    bottom: 0;
    right: 0;
    padding: .3em;
    text-shadow: 1px 1px 1px #000;
  }

  button {
    padding: 1em;
  }
}
