.tabs_component {
  display: flex;
  width: 100%;

  &__tab {
    border: 1px solid #000;
    background-color: rgba(0,0,0,.3);
    padding: 1em;
    z-index: 1231;
    filter: grayscale(1);
    transition: all 200ms ease;
    flex: 1;
    text-align: center;

    &.is-active {
      filter: grayscale(0);
      background-color: rgba(0,0,0,.5);
    }
  }
}
