.SimpleHealthBarComponent {
  background-color: #333;
  font-size: 12px;
  color: #fff;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 2px;
  position: relative;
  overflow: hidden;


  &__bar {
    background-color: #dc2d2d;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: all 200ms ease;
  }

  &__value {
    padding: 0 1em;
    z-index: 999;
    position: relative;
    box-shadow: 0 3px 2px rgba(255,255,255, .4) inset;
  }
}
