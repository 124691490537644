@mixin box {
  border: 1px solid #333;
  background-color: rgba(0,0,0,.4);
  color: #eee;
  padding: .5em;
  pointer-events: all;
}

.view_component{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));
  z-index: 99999;

  @include box();
  padding: 0;

  $nav_height: 55px;

  &__content {
    height: calc(100vh - var(--vh-offset, 0px) - #{$nav_height});
    overflow: auto;
    padding: .5em;
  }

  &__nav {
    height: $nav_height;
    display: flex;
  }

  &__close {
    display: flex;
    border: 1px solid #000;
    background-color: rgba(255,255,255, 1);
    padding: 1em;
    z-index: 1231;
    filter: grayscale(1);
    transition: all 200ms ease;
    flex: 1;
    text-align: center;
  }
}
