@mixin box {

  border: 1px solid #333;
  background-color: rgba(0,0,0,.4);
  color: #eee;
  padding: .5em;
  pointer-events: all;
}

.game_view{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;


  &__target{
    @include box();

    display: flex;
    justify-content: space-between;
  }


  &__error {
    color: #dc2d2d;
    text-shadow: 0px 1px 2px #000, 1px 0px 2px #000, -1px 0px 2px #000, 0px -1px 2px #000;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    background-color: rgba(0,0,0,.1);
  }

  &__bottom_bar{
    z-index: 9999;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include box();
  }

  &__actions {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
}
