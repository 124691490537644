.game_wrapper {
  position: relative;
}


.game_ui {
  position: absolute;
  pointer-events: none;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));

  display: flex;
  flex-direction: column;

  .row {
    display: flex;
  }

  &__error {
    color: #dc2d2d;
    text-shadow: 1px 1px 2px #b9b2b2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 4em;
    text-align: center;
  }
}
