.particle_system {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  &__particle{
    position: absolute;
    top: 0;
    left: 0;
  }
}
